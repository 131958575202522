import React, { useRef} from "react";
import emailjs from 'emailjs-com';
import '../css/selectPlan.css';
import { MdShoppingCart } from 'react-icons/md';
import { FaPlus } from 'react-icons/fa';
import Whatsapp from "./Whatsapp";
import ApiKey from "../api/ApiKey";
import swal from "sweetalert";


const SeleccionPlan = () => {


    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm(ApiKey.SERVICE_ID, ApiKey.TEMPLATE_ID, form.current, ApiKey.PUBLIC_KEY)
            .then((result) => {
                swal(
                    "Good job!",
                    "Tus datos se han enviado de forma correcta, nos pondremos en contacto a la brevedad. Recuerda revisar tu bandeja de correos no deseados o bandeja de spam.",
                    "success");
                    e.target.reset();
            }, (error) => {
                swal("Vuelve a intentarlo");
            }
        );
    }

    return (
        <>
            <div className="container">
                <div className="headerSales">
                    <div className="circleSales">
                        <div className="circleInSales">
                            <div className="circleFaPlus">
                                <div className="circleInFaPlus">
                                    <FaPlus className="faPlus" />
                                </div>
                            </div>
                            <MdShoppingCart className="carSales" />
                        </div>
                    </div>
                </div>

                <div className="container text-center">
                    <h1 className="mb-3">ACTIVA TU CUENTA</h1>
                    <p>Ingresa tus datos</p>
                    <form ref={form} onSubmit={sendEmail} className="headerFormSelect">
                        <div className="input-group mb-4">
                            <div className="input-group-prepend">
                                <span role="img" aria-labelledby="email" className="input-group-text" id="basic-addon1">✉️</span>
                            </div>
                            <input id="email" type="email" name="email" className="form-control" placeholder="E-mail" aria-label="Username" aria-describedby="basic-addon1" required />
                        </div>
                        <div className="input-group mb-4">
                            <div className="input-group-prepend">
                                <span role="img" aria-labelledby="dominio" className="input-group-text" id="basic-addon1">🌐</span>
                            </div>
                            <input type="text" name="dominio" className="form-control" placeholder="Dominio (si no tienes repite el correo)" aria-label="Username" aria-describedby="basic-addon1" required/>
                        </div>
                        <div className="input-group mb-4">
                            <div className="input-group-prepend">
                                <span role="img" aria-labelledby="celular" className="input-group-text" id="basic-addon1">📱​</span>
                            </div>
                            <input type="number" name="number" className="form-control" placeholder="Celular" aria-label="Username" aria-describedby="basic-addon1" required />
                        </div>
                        <button type="submit" value="Send" className="btn btn-primary btn-lg mt-4 mb-4">Activar Cuenta</button>
                    </form>
                    

                    <Whatsapp />

                    
                </div>
            </div>
        </>
    );
}

export default SeleccionPlan;