

const dataPlan = [
    {
        id: 0,
        title: 'PLAN 1000',
        valor: '10.000',
        megas: '1.000',
        transfer: 20,
        base: 2,
        email: 10,
        subdomain: 2,
        divid: 'plan1'
    },
    {
        id: 1,
        title: 'PLAN 2000',
        valor: '20.000',
        megas: '2.000',
        transfer: 40,
        base: 4,
        email: 15,
        subdomain: 5,
        divid: 'plan1'
    },
    {
        id: 2,
        title: 'PLAN 3000',
        valor: '30.000',
        megas: '3.000',
        transfer: 60,
        base: 6,
        email: 20,
        subdomain: 8,
        divid: 'plan2'
    },
    {
        id: 3,
        title: 'PLAN 4000',
        valor: '35.000',
        megas: '4.000',
        transfer: 80,
        base: 10,
        email: 30,
        subdomain: 8,
        divid: 'plan3'
    },
    {
        id: 4,
        title: 'PLAN 5000',
        valor: '40.000',
        megas: '5.000',
        transfer: 100,
        base: 15,
        email: 40,
        subdomain: 10,
        divid: 'plan4'
    },
    {
        id: 5,
        title: 'PLAN 6000',
        valor: '45.000',
        megas: '6.000',
        transfer: 120,
        base: 20,
        email: 50,
        subdomain: 15,
        divid: 'plan5'
    },
]

export default dataPlan;