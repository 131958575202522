import React from "react";
import Header from "./assets/components/Header";
import Info from "./assets/components/Info";
import Planes from "./assets/components/Planes";
import SubMenu from "./assets/components/SubMenu";
import Whatsapp from "./assets/components/Whatsapp";


function App() {
  return (

    <>
      <Header />
      <SubMenu />
      <Info />
      <Planes />
      <Whatsapp/>
    </>

  );

}

export default App;
