import React from 'react';
import '../css/header.css';
import Logo from '../img/logo.png';
// import { BsInstagram, BsYoutube, BsTwitter, BsWhatsapp } from 'react-icons/bs';
// import { FaFacebookF, FaTiktok } from 'react-icons/fa';

const Header = () => {
    return (
        <>
            <div className='background-header'>
                <div className='container-fluid'>
                    <nav className='navbar navbar-expand-md navbar-light header'>
                        <span className='logo'>
                            <img src={Logo} alt='Consof-Hosting' height='80' />
                        </span>
                        {/* <div className='navbar-collapse' id='navbarNav'>
                            <ul className='navbar-nav ms-auto mb-2 mb-lg-0'>
                                <li className='nav-item'>
                                    <a 
                                        href='https://api.whatsapp.com/send/?phone=56973651580&text&type=phone_number&app_absent=0'
                                        target="_blank" rel="noopener noreferrer" 
                                        className='btn btn-lg'>
                                            <BsWhatsapp className='social-networks' />
                                    </a>
                                </li>
                                <li className='nav-item'>
                                    <button className='btn btn-lg'><FaTiktok className='social-networks' /></button>
                                </li>
                                <li className='nav-item'>
                                    <button className='btn btn-lg'><BsTwitter className='social-networks' /></button>
                                </li>
                                <li className='nav-item'>
                                    <button className='btn btn-lg'><FaFacebookF className='social-networks' /></button>
                                </li>
                                <li className='nav-item'>
                                    <button className='btn btn-lg'><BsYoutube className='social-networks' /></button>
                                </li>
                                <li className='nav-item'>
                                    <button className='btn btn-lg'><BsInstagram className='social-networks' /></button>
                                </li>
                            </ul>
                        </div> */}
                    </nav>
                </div>
            </div>
        </>
    );
}

export default Header;
