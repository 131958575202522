import React from 'react';
import Icono from '../img/icono.png';


const SubMenu = () => {

    return (
        <>
            <nav className="navbar navbar-dark bg-dark sticky-top">
                <div className="container-fluid">
                    <button className="navbar-brand btn" href="#">
                        <img src={Icono} width="40" height="40" alt="" /> Hosting
                    </button>
                    <a
                        className="navbar-brand btn" 
                        href="https://www.consof.net:2083/"
                        target="_blank"
                        rel="noopener noreferrer"
                        >Acceso clientes
                    </a>
                    {/* <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarNavAltMarkup"
                        aria-controls="navbarNavAltMarkup"
                        aria-expanded="false"
                        aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button> */}

                </div>
            </nav>
        </>
    );
}

export default SubMenu;