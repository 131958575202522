import React from "react";
import { BsWhatsapp } from 'react-icons/bs';
import { FaUserLock } from 'react-icons/fa';

import '../css/whatsapp.css';


const Whatsapp = () => {
    return (
        <>
            <div className="contwhatsapp">
                <a
                    href="https://api.whatsapp.com/send/?phone=56973651580&text&type=phone_number&app_absent=0"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn-lg"
                >
                    <BsWhatsapp className="whatsapp" />
                </a>
            </div>

            <div className="clientes">
                <a
                    href="https://www.consof.net:2083/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn-lg"
                >
                    <FaUserLock className="acceso" />
                </a>
            </div>
        </>
    )
}

export default Whatsapp;

