import React from 'react';
import styles from '../css/Info.module.css';
import { GiPencilRuler } from 'react-icons/gi';
import { BsCheckCircle } from 'react-icons/bs';
import { IoIosRocket } from 'react-icons/io';
import { GoTriangleUp } from 'react-icons/go';

const DataCardInfo = [
    {
        icon: <GiPencilRuler className={styles.icon} />,
        title: 'Administración fácil de tu hosting',
        text: 'Cpanel te permite administrar de manera directa y rápida tu cuenta de hosting.'
    },
    {
        icon: <BsCheckCircle className={styles.icon} />,
        title: 'Activación inmediata de tu cuenta',
        text: 'Activación inmediata en cualquiera de nuestros planes de hosting.'
    },
    {
        icon: <IoIosRocket className={styles.icon} />,
        title: '30 días de satisfacción garantizada',
        text: 'En caso que nuestro servicio no cumpla tus expectativas, solicita tu devolución.'
    },
];



const Info = () => {

    return (
        <>
            <div className='container'>
                <div className={styles.info}>
                    <div className='row'>
                        {DataCardInfo.map((item, index) => {
                            return (
                                <div key={index} className='col-sm-4 mt-5 mb-3'>
                                    {item.icon}
                                    <div>
                                        <GoTriangleUp className={styles.triangle} />
                                    </div>
                                    <h4 className={styles.title}>
                                        {item.title}
                                    </h4>
                                    <p className={styles.text}>
                                        {item.text}
                                    </p>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Info;