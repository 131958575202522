import React, { useState } from 'react';
import dataPlan from './DataPlanes';
import styles from '../css/Planes.module.css';
import { GoTriangleUp } from 'react-icons/go';
import SeleccionPlan from './SeleccionPlan';
import Modal from './Modal';


const Planes = () => {

    //LLAMADO MODAL
    const [active, setActive] = useState(true);
    const toggle = () => {
        setActive(!active);
        //const card = document.querySelector("#plan1");
        //console.log(card);
    }

    return (
        <>
            <div className=" container">
                <div className={styles.container}>
                    <h5 className={styles.title}>
                        CONSOF HOSTING
                    </h5>
                    <h1>
                        PLANES HOSTING
                    </h1>
                    <div className="row justify-content-center">
                        <div className="col-sm-1 gion">
                        </div>
                    </div>

                    <div className="row justify-content-center mt-5">

                        {dataPlan.map(item => (                         
                            
                                <div id={item.divid} key={item.id} className="col-sm-4 divPlan">
                                    <div className={styles.card}>
                                        <div className={styles.planes}>
                                            <GoTriangleUp className={styles.triangle} />
                                            <h4 className={styles.subtitle}>
                                                {item.title}
                                            </h4>
                                            <div className='m-0 row justify-content-center mt-4'>
                                                <div className='col-auto p-2 text-center'>
                                                    <div className={styles.circle}>
                                                        <div className='col-auto p-2 text-center'>
                                                            <div className={styles.circlein}>
                                                                <h4 className={styles.valor}>
                                                                    ${item.valor}
                                                                </h4>
                                                                <p className='text-white'>Anual + IVA</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={styles.cardlist}>
                                                    <p className={styles.lista}>
                                                        {item.megas} MB de almacenamiento
                                                    </p>
                                                    <p className={styles.lista}>
                                                        {item.transfer} GB transferencia mensual
                                                    </p>
                                                    <p className={styles.lista}>
                                                        {item.base} Bases de datos MySQL
                                                    </p>
                                                    <p className={styles.lista}>
                                                        {item.email} E-mails
                                                    </p>
                                                    <p className={styles.lista}>
                                                        {item.subdomain} Subdominios
                                                    </p>
                                                    <p className={styles.lista}>
                                                        SSL Instalado gratis
                                                    </p>
                                                </div>
                                            </div>
                                            <button
                                                id={item.id}
                                                onClick={toggle}
                                                className='btn btn-outline-warning mt-4 mb-4 btn-lg'
                                                type='button'
                                            >
                                                CONTRATAR
                                            </button>
                                        </div>
                                    </div>
                                </div>
                        )   )
                        }
                        <Modal active={active} toggle={toggle}>
                            <SeleccionPlan />
                            
                        </Modal>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Planes;